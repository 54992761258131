import { IPursuitAppSettings } from '../interface/IPursuitAppSettings';

export const uat: IPursuitAppSettings = {
  cdnBaseUrl: 'https://pursuitexpcdnendpointuat.azureedge.net',
  serviceEndPoints: [
    {
      clientId: '36c9b89c-87e3-428a-aa6c-90c47513e9b5',
      serviceEndPoint: 'https://pursuitexpappserviceuat.trafficmanager.net',
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/o2c/projectmanagementuat/api/v1.0',
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/o2c/projectmanagementuat/api/v2.0',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://servicesuat.microsoft.com/api/v1/Search/',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://esxp-uat.azurefd.net/grm/rm/',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v2.0',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v1',
    },
    {
      clientId: 'c5779b49-c238-45cb-8974-36958d4c7d96',
      serviceEndPoint: 'https://pslakedsdev.azurewebsites.net',
    },
    {
      clientId: 'ef8a1b99-cb01-4f9f-80aa-22ee1d628906',
      serviceEndPoint: 'https://phxgraph-uat.azurewebsites.net/graphql/',
    },
    {
      clientId: 'https://msxuat.crm.dynamics.com',
      serviceEndPoint: 'https://msxuat.crm.dynamics.com/api/data',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://esxp-uat.azurefd.net/grm/staffing/api/assignment/search/',
    },
    {
      clientId:'778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint:'https://esxp-uat.azurefd.net/',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/time-core/api/v2/assignments/labor',
    }
  ],
  CdnEndPoint: 'https://pursuitexpcdnendpointuat.azureedge.net',
  WebApiBaseUrl: 'https://pursuitexpappserviceuat.trafficmanager.net',
  SubscriptionKey: '36c9b89c-87e3-428a-aa6c-90c47513e9b5',
  OneProfileEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v2.0',
  OneProfileV1EndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v1',
  OneProfileSubscriptionKey: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
  ProfessionalservicesEndPoint: 'https://professionalservicesint.microsoft.com/o2c/projectmanagementuat/api/v2.0',
  ProfessionalservicesEndPointWithoutVersion: 'https://professionalservicesint.microsoft.com/o2c/projectmanagementuat',
  ProfessionalservicesSubscriptionKey: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
  ConsultantRoleKey: 'AAA-86580',
  ProfessionalservicesOCMSubscriptionKey: 'dc42c2d47e5d4162aa39d4114015717d',
  resourceGRMserviceEndPoint: "https://esxp-uat.azurefd.net/grm/staffing/api/assignment/search/",
  GrmEndPoint: 'https://esxp-uat.azurefd.net/grm/rm/',
  GrmSearchEndPoint: 'https://servicesuat.microsoft.com/api/v1/Search/',
  GrmFDUrl:'https://esxp-uat.azurefd.net/',
  GrmBravosOfferingServiceEndpoint:'grm/read/api/bravos/offering',
  GrmReadApiEndPoint:'grm/read/api/Search',
  GrmBravosAggrementServiceEndPoint:'grm/read/api/bravos/isAggrementExists?tpid=',
  GrmUnifiedAndDecrementType:'grm/read/api/bravos/isUnifiedAndDecrementType?tpid=',
  AzureSearchIndex: 'pmexpuat/pmexp-requestsearch-uat-index',
  AzureSearchSuggester: '/pmexp-requestsearch-uat-index-suggest/suggest',
  JediAPIEndPoint: 'https://pslakedsdev.azurewebsites.net/pmxp',
  GrmSearchEndPointSubscriptionKey: '778329d6-a980-4f3e-bf02-7bc44a384e15',
  GrmOCMSubscriptionKey: '4f1ac558346d474facea88125516f6eb',
  envName: 'UAT',
  appName: 'PursuitManagementExperience',
  dealRiskEstimator: 'https://aka.ms/riskestimator',
  MaximumBudgetCR: 15,
  maxIteration: 15,
  WarningMessageCode: '9999',
  UsSpecialHandlingCodes: '97124,10000745,10000747,10000737,10000739,10000741,10128514,10128513,89084,97125,10000749,10000775,10000779',
  PreQualTeamIds: '66,84,85,100,101,56,34,98,150',
  MSXNavigationBaseURL:
    'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=opportunity&id=',
  StaffingDomainRoleList: ['AAA-66364', 'AAA-59863', 'AAA-86580', 'AAA-66362', 'AAA-86585', 'AAA-66283', 'AAA-66322', 'AAA-66295', 'AAA-66298',
  'AAA-66320', 'AAA-66299', 'AAA-66300', 'AAA-66301', 'AAA-59862', 'AAA-86582', 'AAA-66370', 'AAA-66302', 'AAA-86591', "AAA-66275", "AAA-66280", 'AAA-59861','AAA-86588','AAA-66333','AAA-66336','AAA-86608','AAA-86609','AAA-86592','AAA-66338','AAA-66327','AAA-86619','AAA-86620','AAA-86618','AAA-86621','AAA-86617','AAA-66340','AAA-66350','AAA-86589','AAA-59860','AAA-66355','AAA-86594','AAA-66352','AAA-66351','AAA-66357','AAA-86607','AAA-66359','AAA-86604','AAA-86603','AAA-86726','AAA-86735',
  'AAA-86750','AAA-86751','AAA-86742','AAA-86741','AAA-86749','AAA-86715','AAA-86746','AAA-86722','AAA-86745','AAA-86740','AAA-86748','AAA-86743','AAA-86744','AAA-86747'],
  phoenixadmin: "https://phxgraph-uat.azurewebsites.net/graphql/",
  phoenixSubscriptionKey: "4e6d554bfb8743678e98d5dc1bc32424",
  phoenixApiVersion: '2017-06-14',
  showJediWinProbability: false,
  CRMEndPoint: "https://msxuat.crm.dynamics.com/api/data",
  MSXEngagementNavigationBaseURL: 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagement&id=',
  MSXMilestoneNavigationBaseURL: 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagementmilestone&id=',
  MSXLeadNavigationBaseURL: 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=lead&id=',

  //csa appsettings
  csaAzureSearchIndex: 'pmexpuat/csaxp-requestsearch-uat-index',
  csaAzureSearchSuggester: '/csaxp-requestsearch-uat-index-suggest/suggest',
  csaGrmAzureSearchIndex: 'grmuat/grm-assignmentsearch-uat-index',
  GrmBaseUri: 'https://esxp-uat.azurefd.net/grm/rm/',
  CEBusinessRoleIds:"193",
  CEAndCSABusinessRoleIds: "193,161" ,
  TimeEndPoint: "https://professionalservicesint.microsoft.com/time-core/api/v2/assignments/labor",
  TIMEApiSubscriptionKey: 'fd723c776fbd432fb12471dcf25d5c10',
  TimeBaseUri: "https://professionalservicesint.microsoft.com/time-core",
  MSApprovalURL: 'https://uat.msapprovals.microsoft.com/#/pendingapprovalstableview',
  ThresholdRequestId: 194918,  
};
