import { IPursuitAppSettings } from '../interface/IPursuitAppSettings';

export const prod: IPursuitAppSettings = {
  cdnBaseUrl: 'https://pursuitexpcdnendpointprod.azureedge.net',
  serviceEndPoints: [
    {
      clientId: 'f9daa99c-6570-4781-a17c-d0c2d617ed6e',
      serviceEndPoint: 'https://pursuitexpappserviceprod.trafficmanager.net',
    },
    {
      clientId: '83902542-ffa9-4272-9d09-31b93eccabfa',
      serviceEndPoint: 'https://professionalservices.microsoft.com/projectmanagement/api/v1.0',
    },
    {
      clientId: '83902542-ffa9-4272-9d09-31b93eccabfa',
      serviceEndPoint: 'https://professionalservices.microsoft.com/projectmanagement/api/v2.0',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp.microsoft.com/api/v1/Search/',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp-prod.azurefd.net/grm/rm/',
    },
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://oneprofileprodapipack.azurefd.net/api/v2.0',
    },
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://oneprofileprodapipack.azurefd.net/api/v1',
    },
    {
      clientId: 'e03cd7c9-8c94-45dd-b4b8-68957267ec20',
      serviceEndPoint: 'https://pslakeds.azurewebsites.net',
    },
    {
      clientId: 'ff2089b4-e658-4d62-9a4d-40a2bc6169bd',
      serviceEndPoint: 'https://phxgraph.microsoft.com/graphql/',
    },
    {
      clientId: 'https://microsoftsales.crm.dynamics.com',
      serviceEndPoint: 'https://microsoftsales.crm.dynamics.com/api/data',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp-prod.azurefd.net/grm/staffing/api/assignment/search/',
    },
    {
      clientId:'72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint:'https://esxp-prod.azurefd.net/',
    },
    {
      clientId:'22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint:'https://professionalservices.microsoft.com/lmt-coreapi/api/v2/assignments/labor',
    }
  ],
  CdnEndPoint: 'https://pursuitexpcdnendpointprod.azureedge.net',
  WebApiBaseUrl: 'https://pursuitexpappserviceprod.trafficmanager.net',
  SubscriptionKey: 'f9daa99c-6570-4781-a17c-d0c2d617ed6e',
  OneProfileEndPoint: 'https://oneprofileprodapipack.azurefd.net/api/v2.0',
  OneProfileV1EndPoint: 'https://oneprofileprodapipack.azurefd.net/api/v1',
  OneProfileSubscriptionKey: '6db0b12a-edd4-4521-8406-a615ce8659d6',
  ProfessionalservicesEndPoint: 'https://professionalservices.microsoft.com/projectmanagement/api/v2.0',
  ProfessionalservicesEndPointWithoutVersion: 'https://professionalservices.microsoft.com/projectmanagement',
  resourceGRMserviceEndPoint: "https://esxp-prod.azurefd.net/grm/staffing/api/assignment/search/",
  ProfessionalservicesSubscriptionKey: '83902542-ffa9-4272-9d09-31b93eccabfa',
  ConsultantRoleKey: 'AAA-86580',
  ProfessionalservicesOCMSubscriptionKey: 'ed3cd6b9cd9b454ca93264c5b3351220',
  GrmEndPoint: 'https://esxp-prod.azurefd.net/grm/rm/',
  GrmSearchEndPoint: 'https://esxp.microsoft.com/api/v1/Search/',
  GrmFDUrl:'https://esxp-prod.azurefd.net/',
  GrmBravosOfferingServiceEndpoint:'grm/read/api/bravos/offering',
  GrmBravosAggrementServiceEndPoint:'grm/read/api/bravos/isAggrementExists?tpid=',
  GrmUnifiedAndDecrementType:'grm/read/api/bravos/isUnifiedAndDecrementType?tpid=',
  GrmReadApiEndPoint:'grm/read/api/Search',
  AzureSearchIndex: 'pmexpprod/pmexp-requestsearch-prod-index',
  AzureSearchSuggester: '/pmexp-requestsearch-prod-index-suggest/suggest',
  JediAPIEndPoint: 'https://pslakeds.azurewebsites.net/pmxp',
  GrmSearchEndPointSubscriptionKey: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
  GrmOCMSubscriptionKey: 'c31fa96910b347a49c03be14be329b71',
  envName: 'Prod',
  appName: 'PursuitManagementExperience',
  dealRiskEstimator: 'https://aka.ms/riskestimator',
  MaximumBudgetCR: 15,
  maxIteration: 15,
  WarningMessageCode: '9999',
  UsSpecialHandlingCodes: '97124,10000745,10000747,10000737,10000739,10000741,10128514,10128513,89084,97125,10000749,10000775,10000779',
  PreQualTeamIds: '66,84,85,100,101,56,34,98,150',
  MSXNavigationBaseURL:
    'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=opportunity&id=',
  StaffingDomainRoleList: ['AAA-66364', 'AAA-59863', 'AAA-86580', 'AAA-66362', 'AAA-86585', 'AAA-66283', 'AAA-66322', 'AAA-66295', 'AAA-66298',
  'AAA-66320', 'AAA-66299', 'AAA-66300', 'AAA-66301', 'AAA-59862', 'AAA-86582', 'AAA-66370', 'AAA-66302', 'AAA-86591', "AAA-66275", "AAA-66280", 'AAA-59861','AAA-86588','AAA-66333','AAA-66336','AAA-86608','AAA-86609','AAA-86592','AAA-66338','AAA-66327','AAA-86619','AAA-86620','AAA-86618','AAA-86621','AAA-86617','AAA-66340','AAA-66350','AAA-86589','AAA-59860','AAA-66355','AAA-86594','AAA-66352','AAA-66351','AAA-66357','AAA-86607','AAA-66359','AAA-86604','AAA-86603','AAA-86726','AAA-86735',
  'AAA-86750','AAA-86751','AAA-86742','AAA-86741','AAA-86749','AAA-86715','AAA-86746','AAA-86722','AAA-86745','AAA-86740','AAA-86748','AAA-86743','AAA-86744','AAA-86747'],
  phoenixadmin: "https://phxgraph.microsoft.com/graphql/",
  phoenixSubscriptionKey: "55bfa272d6644a21ad77d0e73bc8ccf9",
  phoenixApiVersion: '2017-06-14',
  showJediWinProbability: false,
  CRMEndPoint: "https://microsoftsales.crm.dynamics.com/api/data",
  MSXEngagementNavigationBaseURL: 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagement&id=',
  MSXMilestoneNavigationBaseURL: 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagementmilestone&id=',
  MSXLeadNavigationBaseURL:'https://microsoftsales.crm.dynamics.com//main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=lead&id=',

  //csa appsettings
  csaAzureSearchIndex: 'pmexpprod/csaxp-requestsearch-prod-index',
  csaAzureSearchSuggester: '/csaxp-requestsearch-prod-index-suggest/suggest',
  csaGrmAzureSearchIndex: 'grmprod/grm-assignmentsearch-prod-index',
  GrmBaseUri: 'https://esxp-prod.azurefd.net/grm/rm/',
  CEBusinessRoleIds:"187",
  CEAndCSABusinessRoleIds: "187,159",
  TimeEndPoint: "https://professionalservices.microsoft.com/lmt-coreapi/api/v2/assignments/labor",
  TIMEApiSubscriptionKey: 'acc2cbb3f24e4f7ba55220041d0ce2fd',
  TimeBaseUri: "https://professionalservices.microsoft.com/lmt-coreapi",
  MSApprovalURL: 'https://msapprovals.microsoft.com/#/pendingapprovalstableview',
  ThresholdRequestId: 218314,
};
