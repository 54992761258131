import { IPursuitAppSettings } from '../interface/IPursuitAppSettings';

export const dev: IPursuitAppSettings = {
  cdnBaseUrl: 'http://localhost:5003',
  serviceEndPoints: [
    {
      clientId: '07ef52f9-8b5c-4767-b1c7-5f8450850c42',
      serviceEndPoint: 'http://localhost:26390'
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/o2c/projectmanagementsit/api/v1.0',
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/o2c/projectmanagementsit/api/v2.0',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://servicesuat.microsoft.com/api/v1/Search/',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.azurefd.net',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.azurefd.net'
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://grmapiappsit.azurewebsites.net',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v2.0',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v1',
    },
    {
      clientId: 'c5779b49-c238-45cb-8974-36958d4c7d96',
      serviceEndPoint: 'https://pslakedsdev.azurewebsites.net',
    },
    {
      clientId: '8d2d1669-d68d-40f0-a4b3-8a4001f8478e',
      serviceEndPoint: 'https://phxgraph-dev.azurewebsites.net/graphql/',
    },
    {
      clientId: 'https://msxuat.crm.dynamics.com',
      serviceEndPoint: 'https://msxuat.crm.dynamics.com/api/data',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.azurefd.net/grm/staffing/api/assignment/search/',
    },
    {
      clientId:'38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint:'https://esxp-sit.azurefd.net/',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/Time2-SIT/api/v2/assignments/labor',
    }
  ],
  CdnEndPoint: 'https://pursuitexpcdnendpointdit.azureedge.net',
  WebApiBaseUrl: 'http://localhost:26390',
  SubscriptionKey: "07ef52f9-8b5c-4767-b1c7-5f8450850c42",
  OneProfileEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v2.0',
  OneProfileV1EndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v1',
  OneProfileSubscriptionKey: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
  ProfessionalservicesEndPoint: 'https://professionalservicesint.microsoft.com/o2c/projectmanagement/api/v2.0',
  ProfessionalservicesEndPointWithoutVersion: 'https://professionalservicesint.microsoft.com/o2c/projectmanagement',
  ProfessionalservicesSubscriptionKey: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
  ProfessionalservicesOCMSubscriptionKey: 'ed1908e47d8247ca8459a49e9c6b2ed2',
  ConsultantRoleKey: 'AAA-86580',
  GrmEndPoint: 'https://esxp-sit.azurefd.net',
  GrmSearchEndPoint: 'https://servicesuat.microsoft.com/api/v1/Search/',
  GrmFDUrl:'https://esxp-sit.azurefd.net/',
  GrmBravosOfferingServiceEndpoint:'grm/read/api/bravos/offering',
  GrmReadApiEndPoint:'grm/read/api/Search',
  GrmBravosAggrementServiceEndPoint:'grm/read/api/bravos/isAggrementExists?tpid=',
  GrmUnifiedAndDecrementType:'grm/read/api/bravos/isUnifiedAndDecrementType?tpid=',
  resourceGRMserviceEndPoint: "https://esxp-sit.azurefd.net/grm/staffing/api/assignment/search/",
  AzureSearchIndex: 'pmexpsit/pmexp-requestsearch-sit-index',
  AzureSearchSuggester: '/pmexp-requestsearch-sit-index-suggest/suggest',
  JediAPIEndPoint: 'https://pslakedsdev.azurewebsites.net/pmxp',
  GrmSearchEndPointSubscriptionKey: '38578c27-d821-45d3-95db-fcf0e8371e68',
  GrmOCMSubscriptionKey: '4f1ac558346d474facea88125516f6eb',
  envName: 'Dev',
  appName: 'PursuitManagementExperience',
  dealRiskEstimator: 'https://aka.ms/riskestimator',
  MaximumBudgetCR: 15,
  maxIteration: 15,
  WarningMessageCode: '9999',
  UsSpecialHandlingCodes: '97124,10000745,10000747,10000737,10000739,10000741,10128514,10128513,89084,97125,10000749,10000775,10000779',
  PreQualTeamIds: '66,84,85,100,101,56,34,98,156',
  MSXNavigationBaseURL:
    'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=opportunity&id=',
  StaffingDomainRoleList: ['AAA-66364', 'AAA-59863', 'AAA-86580', 'AAA-66362', 'AAA-86585', 'AAA-66283', 'AAA-66322', 'AAA-66295', 'AAA-66298',
  'AAA-66320', 'AAA-66299', 'AAA-66300', 'AAA-66301', 'AAA-59862', 'AAA-86582', 'AAA-66370', 'AAA-66302', 'AAA-86591', "AAA-66275", "AAA-66280", 'AAA-59861','AAA-86588','AAA-66333','AAA-66336','AAA-86608','AAA-86609','AAA-86592','AAA-66338','AAA-66327','AAA-86619','AAA-86620','AAA-86618','AAA-86621','AAA-86617','AAA-66340','AAA-66350','AAA-86589','AAA-59860','AAA-66355','AAA-86594','AAA-66352','AAA-66351','AAA-66357','AAA-86607','AAA-66359','AAA-86604','AAA-86603','AAA-86726','AAA-86735',
  'AAA-86750','AAA-86751','AAA-86742','AAA-86741','AAA-86749','AAA-86715','AAA-86746','AAA-86722','AAA-86745','AAA-86740','AAA-86748','AAA-86743','AAA-86744','AAA-86747'],
  phoenixadmin: " https://phxgraph-dev.azurewebsites.net/graphql/",
  phoenixSubscriptionKey: "4e6d554bfb8743678e98d5dc1bc32424",
  phoenixApiVersion: '2017-06-14',
  showJediWinProbability: false, CRMEndPoint: "https://msxuat.crm.dynamics.com/api/data",
  MSXEngagementNavigationBaseURL: 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagement&id=',
  MSXMilestoneNavigationBaseURL: 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_engagementmilestone&id=',
  MSXLeadNavigationBaseURL: 'https://msxuat.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=lead&id=',

  //csa appsettings
  csaAzureSearchIndex: 'pmexpsit/csaxp-requestsearch-sit-index',
  csaAzureSearchSuggester: '/csaxp-requestsearch-sit-index-suggest/suggest',
  csaGrmAzureSearchIndex: 'grmsit/grm-assignmentsearch-sit-index',
  GrmBaseUri: "https://professionalservicesint.microsoft.com/gcm/grm/int",
  CEBusinessRoleIds:"193",
  CEAndCSABusinessRoleIds: "193,161"  ,
  TimeEndPoint: "https://professionalservicesint.microsoft.com/Time2-SIT/api/v2/assignments/labor",
  TIMEApiSubscriptionKey: 'b7f08ad447dd45f490a0f812eaef7bfc',
  TimeBaseUri: "https://professionalservicesint.microsoft.com/Time2-SIT",
  MSApprovalURL: 'https://test.msapprovals.microsoft.com/#/pendingapprovalstableview',
  ThresholdRequestId: 179615,
};

