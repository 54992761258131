import { IAppService, IFxpAppContext, IPartnerBundle, IRouteInfo, IServiceEndPoints, IStateConfig, IAppServiceWithSharedComponent, ISharedComponents, ISharedComponentConfig } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appSettingsGenerator';
import { CSAModule } from './app/csa-app/csa.module';
import { PmexpOpportunityEngagementsComponent } from './app/pmexp-features/pmexp-pursuit/components/pmexp-opportunity-engagements/pmexp-opportunity-engagements.component';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class PmexApp_routes implements IAppService {

	getServiceEndPoints(): IServiceEndPoints[] {
		return appSettings().serviceEndPoints;
	}

	getRoutes(userContext: IFxpAppContext): IRouteInfo {
		const pmExpDashboard: IStateConfig = {
			name: 'pmexplanding',
			url: '/pmexplanding',
			redirectTo: 'pmexp.dashboard',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Pursuits',
				pageTitle: 'Pursuit Management',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'Dashboard Landing',
				partnerTelemetryName: "PursuitExp",
			}
		};
		const pmExpDashboardTab: IStateConfig = {
			name: 'pmexplanding.tab',
			url: '/:tab',
			redirectTo: 'pmexp.dashboard',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: '',
				pageTitle: 'Pursuit Management',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			}
		};

		const pmExpDashboardTabRequestId: IStateConfig = {
			name: 'pmexplanding.id',
			url: '/:tab/:id',
			data: {
				headerName: 'Pursuit View',
				breadcrumbText: '',
				pageTitle: 'Pursuit View',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpDashboardTabRequestTabId: IStateConfig = {
			name: 'pmexplanding.tab.id',
			url: '/:tab/:id',
			data: {
				headerName: 'Pursuit View',
				breadcrumbText: 'Pursuit View',
				pageTitle: 'Pursuit View',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpDashboardTabRequest: IStateConfig = {
			name: 'pmexplanding.requestdetails',
			url: '/requestdetails/:id/:tab',
			redirectTo: 'request.requestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpprequalDashboardTabRequest: IStateConfig = {
			name: 'pmexplanding.prequalrequestdetails',
			url: '/prequalrequestdetails/:id/:tab',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpDashboardTabRequestIdRequest: IStateConfig = {
			name: 'pmexplanding.id.requestdetails',
			url: '/requestdetails',
			redirectTo: 'request.requestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpPreQualDashboardTabRequestIdRequest: IStateConfig = {
			name: 'pmexplanding.id.prequalrequestdetails',
			url: '/prequalrequestdetails',
			redirectTo: 'request.prequalrequestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpDashboardTabRequestIdPursuitPlan: IStateConfig = {
			name: 'pmexplanding.id.pursuitplan',
			url: '/pursuitplan',
			redirectTo: 'request.opportunityPlan',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Close Schedule',
				pageTitle: 'Close Schedule',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpDashboardTabRequestIdStaffing: IStateConfig = {
			name: 'pmexplanding.id.staffing',
			url: '/staffing',
			redirectTo: 'request.staffing',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Staffing',
				pageTitle: 'Staffing',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
				pageNameForTelemetry: 'Staffing',
				appNameForTelemetry: 'PursuitExp',				
				featureName:"StaffingDetails",				
				actionName:"ViewStaffingDetails"
			},
		};

		const pmexpdetails: IStateConfig = {
			name: 'pmexpdetails',
			url: '/pmexpdetails',
			redirectTo: 'request.requestdetails',
			data: {
				headerName: 'Pursuit View Details',
				breadcrumbText: 'Pursuit View Details',
				pageTitle: 'Pursuit View Details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmexppursuitplan: IStateConfig = {
			name: 'pmexppursuitplan',
			url: '/pmexppursuitplan',
			data: {
				headerName: 'Close Schedule',
				breadcrumbText: 'Close Schedule',
				pageTitle: 'Close Schedule',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmexppursuitstatus: IStateConfig = {
			name: 'pmexplanding.id.pursuitstatus',
			url: '/pursuitstatus',
			redirectTo: 'request.pursuitstatus',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Pursuit Status',
				pageTitle: 'Pursuit Status',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpPresalesHours: IStateConfig = {
			name: 'pmexplanding.id.presalesHours',
			url: '/presalesHours',
			redirectTo: 'request.presalesHours',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Presales Hours',
				pageTitle: 'Presales Hours',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmExpRequestHistory: IStateConfig = {
			name: 'pmexplanding.id.requesthistory',
			url: '/requesthistory',
			redirectTo: 'request.requesthistory',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request History',
				pageTitle: 'Request History',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		// const pmExpRequestSucessEngagements: IStateConfig = {
		// 	name: 'pmexplanding.id.successengagements',
		// 	url: '/successengagements',
		// 	redirectTo: 'request.successengagements',
		// 	data: {
		// 		headerName: 'Pursuit Management',
		// 		breadcrumbText: 'Sucess Engagements',
		// 		pageTitle: 'Sucess Engagements',
		// 		style: 'icon icon-people',
		// 		partnerTelemetryName: "PursuitExp",
		// 	},
		// };
		const pmExpOrbReview: IStateConfig = {
			name: 'pmexplanding.id.orbreview',
			url: '/orbreview',
			redirectTo: 'request.orbreview',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'ORB Review',
				pageTitle: 'ORB Review',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmexpPursuitTeam: IStateConfig = {
			name: 'pmexplanding.id.team',
			url: '/team',
			redirectTo: 'request.team',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Team',
				pageTitle: 'Team',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmexpPursuitComments: IStateConfig = {
			name: 'pmexplanding.id.comments',
			url: '/comments',
			redirectTo: 'request.comments',
			data: {
				headerName: 'PursuitManagement',
				breadcrumbText: 'Comments',
				pageTitle: 'Comments',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			}
		}


		//#region: Enhanced Search
		const pmexpRequestDashboard: IStateConfig = {
			name: 'request',
			url: '/request/:id',
			data: {
				headerName: 'Pursuit View',
				breadcrumbText: 'Pursuit View',
				pageTitle: 'Pursuit View',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmexpRequestdetails: IStateConfig = {
			name: 'request.requestdetails',
			url: '/requestdetails',
			redirectTo: 'request.requestdetails.customer',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'RequestDetails',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmexpPrequalRequestdetails: IStateConfig = {
			name: 'request.prequalrequestdetails',
			url: '/prequalrequestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'prequalrequestdetails',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmexpRequestHistory: IStateConfig = {
			name: 'request.requesthistory',
			url: '/requesthistory',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request History',
				pageTitle: 'Request History',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'requesthistory',
				partnerTelemetryName: "PursuitExp",
			},
		};
		// const pmexpRequestSucessEngagements: IStateConfig = {
		// 	name: 'request.successengagements',
		// 	url: '/successengagements',
		// 	component: PmexpOpportunityEngagementsComponent,
		// 	data: {
		// 		headerName: 'Pursuit Management',
		// 		breadcrumbText: 'Success Engagements',
		// 		pageTitle: 'Success Engagements',
		// 		style: 'icon icon-people',
		// 		appNameForTelemetry: 'PursuitExp',
		// 		pageNameForTelemetry: 'successengagements',
		// 		partnerTelemetryName: "PursuitExp",
		// 	},
		// };
		const pmexpPursuitStatus: IStateConfig = {
			name: 'request.pursuitstatus',
			url: '/pursuitstatus',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Pursuit Status',
				pageTitle: 'Pursuit Status',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'pursuitstatus',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmexpOrbReview: IStateConfig = {
			name: 'request.orbreview',
			url: '/orbreview',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'ORB Review',
				pageTitle: 'ORB Review',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'orbreview',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmexpPresalesHours: IStateConfig = {
			name: 'request.presalesHours',
			url: '/presalesHours',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Presales Hours',
				pageTitle: 'Presales Hours',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'presalesHours',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmexpRequestIdStaffing: IStateConfig = {
			name: 'request.staffing',
			url: '/staffing',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Staffing',
				pageTitle: 'Staffing',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'staffing',
				partnerTelemetryName: "PursuitExp",							
				featureName:"StaffingDetails",				
				actionName:"ViewStaffingDetails"
			},
		};
		const pmexpRequestPursuitPlan: IStateConfig = {
			name: 'request.opportunityplan',
			url: '/opportunityplan',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Close Schedule',
				pageTitle: 'Close Schedule',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'opportunityplan',
				partnerTelemetryName: "PursuitExp",							
				featureName:"MCSClosePlanDetails",				
				actionName:"ViewMCSClosePlan"
			},
		};

		const pmexpPursuitTeamAdv: IStateConfig = {
			name: 'request.team',
			url: '/team',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Team',
				pageTitle: 'Team',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'team',
				partnerTelemetryName: "PursuitExp",							
				featureName:"PursuitRequestTeam",				
				actionName:"ViewPursuitRequestTeam"
			},
		};

		const pmexpPursuitCommentsAdv: IStateConfig = {
			name: 'request.comments',
			url: '/comments',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Comments',
				pageTitle: 'Comments',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'comments',
				partnerTelemetryName: "PursuitExp",
				featureName:"PursuitRequestComments",				
				actionName:"ViewPursuitRequestComments"
			},
		};

		const pmexpPursuitSolutioning: IStateConfig = {
			name: 'request.solutioning',
			url: '/solutioning',
			redirectTo: 'request.requestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Solution',
				pageTitle: 'Solution',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'solutioning',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmExpAdvanceSearch: IStateConfig = {
			name: 'pmexpadvancesearch',
			url: '/pmadvancesearch',
			redirectTo: 'pmexp.search',
			data: {
				headerName: 'Advanced Search',
				breadcrumbText: 'Advanced Search',
				pageTitle: 'Advanced Search',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'pmadvancesearch',
				partnerTelemetryName: "PursuitExp",
			},
		};
		//#endregion:Enhanced Search

		const pmexpApproverManagement: IStateConfig = {
			name: 'approvermgmt',
			url: '/approvermgmt',
			data: {
				headerName: 'Administration',
				breadcrumbText: 'Approver Management',
				pageTitle: 'Approver Management',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'approvermgmt',
				partnerTelemetryName: "PursuitExp",
				featureName:"ApproverManagement",				
				actionName:"ManageNbueApprovers"
			},
		};
		const pmexpOfferingManagement: IStateConfig = {
			name: 'offeringmgmt',
			url: '/offeringmgmt',
			data: {
				headerName: 'Administration',
				breadcrumbText: 'Offerings Management',
				pageTitle: 'Offerings Management',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'offeringmgmt',
				partnerTelemetryName: "PursuitExp",
				featureName:"OfferingsManagement",				
				actionName:"ManageOfferings"
			},
		};
		const pmexpRoleManagement: IStateConfig = {
			name: 'rolemanagement',
			url: '/rolemanagement',
			data: {
				headerName: 'Role Management',
				breadcrumbText: 'Role Management',
				pageTitle: 'Role Management',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'rolemanagement',
				partnerTelemetryName: "PursuitExp",
				featureName:"RoleManagement",				
				actionName:"ManageRoles"
			},
		};
		const pmexpUnauthorized: IStateConfig = {
			name: 'pmunauthorized',
			url: '/pmunauthorized',
			data: {
				headerName: 'Unauthorized',
				breadcrumbText: 'Unauthorized',
				pageTitle: 'Unauthorized',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'pmunauthorized',
				partnerTelemetryName: "PursuitExp",
			},
		};
		const pmexpPursuitPlanManagement: IStateConfig = {
			name: 'pursuitplanadminmgmt',
			url: '/pursuitplanadminmgmt',
			data: {
				headerName: 'Administration',
				breadcrumbText: 'Close Schedule Management',
				pageTitle: 'Close Schedule Management',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'pursuitplanadminmgmt',
				partnerTelemetryName: 'PursuitExp',
				featureName:"MCSClosePlanManagement",				
				actionName:"ManageMCSClosePlanTasks"
			},
		};
		const pmexpLanding: IStateConfig = {
			name: 'pmexp',
			url: '/pmexp',
			redirectTo: 'pmexp.dashboard',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Pursuit Management',
				pageTitle: 'Pursuit Management',
				featureName: 'PmexpCommonLanding',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'pmexp',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmexpDashboard: IStateConfig = {
			name: 'pmexp.dashboard',
			url: '/dashboard',
			data: {
				headerName: 'Dashboard',
				breadcrumbText: 'Dashboard',
				pageTitle: 'Dashboard',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'dashboard',
				partnerTelemetryName: "PursuitExp",
				featureName:"UserDashboard",
				actionName:"ViewDashboard"
			},
		};
		const pmexpDashboardSearch: IStateConfig = {
			name: 'pmexp.search',
			url: '/search',
			data: {
				headerName: 'Search',
				breadcrumbText: 'Search',
				pageTitle: 'Search',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'search',
				partnerTelemetryName: "PursuitExp",
				featureName:"AdvancedSearch",
				actionName:"SearchPursuitRequests"
			},
		};
		const pmexpDashboardSearchWithParam: IStateConfig = {
			name: 'pmexp.searchparam',
			url: '/search/:origin/:tab',
			data: {
				headerName: 'Search',
				breadcrumbText: 'Search',
				pageTitle: 'Search',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmexpDashboardRequest: IStateConfig = {
			name: 'pmexp.request',
			url: '/request/:id',
			data: {
				headerName: 'Pursuit View',
				breadcrumbText: 'Pursuit View',
				pageTitle: 'Pursuit View',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const pmexpDashboardRequestDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails',
			url: '/requestdetails',
			redirectTo: 'pmexp.request.requestdetails.customer',
			data: {
				headerName: 'Request Details',
				breadcrumbText: 'Request Details',
				pageTitle: 'Request Details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'requestdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				actionName:"ViewPresalesRequestDetails"
			},
		};

		const pmexpDashboardPrequalRequestDetails: IStateConfig = {
			name: 'pmexp.prequalrequestdetails',
			url: '/prequalrequestdetails/:id/:tab',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'requestdetails',
				featureName:"PrequalRequestDetails",
				actionName:"ViewPrequalRequestDetails"
			},
		};

		const pmexpDashboardPursuitCommentsAdv: IStateConfig = {
			name: 'pmexp.request.comments',
			url: '/comments',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Comments',
				pageTitle: 'Comments',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'comments',
				partnerTelemetryName: "PursuitExp",
				featureName:"RequestComments",
				actionName:"ViewRequestComments"
			},
		};
		const pmexpDashboardPursuitStatusAdv: IStateConfig = {
			name: 'pmexp.request.pursuitstatus',
			url: '/pursuitstatus',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Pursuit Status',
				pageTitle: 'Pursuit Status',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'pursuitstatus',
				partnerTelemetryName: "PursuitExp"
			},
		};

		const pmexpDashboardPursuitOrbReviewAdv: IStateConfig = {
			name: 'pmexp.request.orbreview',
			url: '/orbreview',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'ORB Review',
				pageTitle: 'ORB Review',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'orbreview',
				partnerTelemetryName: "PursuitExp"
			},
		};

		const pmexpRequestPursuitPlanSaved: IStateConfig = {
			name: 'pmexp.request.opportunityplan',
			url: '/opportunityplan',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Close Schedule',
				pageTitle: 'Close Schedule',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'opportunityplan',
				partnerTelemetryName: "PursuitExp",
				featureName:"MCSClosePlan",
				actionName:"ViewMCSClosePlan"
			},
		};

		// const pmexpRequestSucessEnaggaementSaved: IStateConfig = {
		// 	name: 'pmexp.request.successengagements',
		// 	url: '/successengagements',
		// 	component: PmexpOpportunityEngagementsComponent,
		// 	data: {
		// 		headerName: 'Pursuit Management',
		// 		breadcrumbText: 'Sucess Engagements',
		// 		pageTitle: 'Sucess Engagements',
		// 		style: 'icon icon-people',
		// 		appNameForTelemetry: 'PursuitExp',
		// 		pageNameForTelemetry: 'successengagements',
		// 		partnerTelemetryName: "PursuitExp",
		// 		featureName:"OpportunitySuccessEngagements",
		// 		actionName:"ViewOpportunitySuccessEngagements"
		// 	},
		// };		

		const pmexpDashboardPursuitTeamAdv: IStateConfig = {
			name: 'pmexp.request.team',
			url: '/team',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Team',
				pageTitle: 'Team',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp', 
				pageNameForTelemetry: 'team',
				partnerTelemetryName: "PursuitExp",
				featureName:"PursuitRequestTeam",
				actionName:"ViewPursuitRequestTeam"
			},
		};

		const pmexpAnnouncementComponent: IStateConfig = {
			name: 'announcement',
			url: '/announcement',
			data: {
				headerName: 'Announcement',
				breadcrumbText: 'Announcement',
				pageTitle: 'Announcement',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'announcement',
				partnerTelemetryName: "PursuitExp",
				featureName:"Announcement",
				actionName:"ManageAnnouncements"
			},
		};

		const pmexpInitiativeManagement: IStateConfig = {
			name: 'initiativemanagement',
			url: '/initiativemanagement',
			data: {
				headerName: 'Initiative Management',
				breadcrumbText: 'Initiative Management',
				pageTitle: 'Initiative Management',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'initiativemanagement',
				partnerTelemetryName: "PursuitExp",
				featureName:"Initiatives",
				actionName:"ManageInitiatives"
			},
		};

		// Request Details
		const pmexpCustomerDetails: IStateConfig = {
			name: 'request.requestdetails.customer',
			url: '/customer',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'customer',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "CustomerDetails",
				actionName:"ViewCustomerDetails"
			},
		};
		const pmexpDashboardRequestCustomerDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails.customer',
			url: '/customer',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'customer',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "CustomerDetails",
				actionName:"ViewCustomerDetails"
			},
		};
		const pmexpOpportunityDetails: IStateConfig = {
			name: 'request.requestdetails.opportunity',
			url: '/opportunity',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'opportunity',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "OpportunityDetails",
				actionName:"ViewOpportunityDetails"
			},
		};
		const pmexpDashboardRequestOpportunityDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails.opportunity',
			url: '/opportunity',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'opportunity',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "OpportunityDetails",
				actionName:"ViewOpportunityDetails"
			},
		};

		const pmexpCustomerOrbDetails: IStateConfig = {
			name: 'request.requestdetails.orbcustomerdetails',
			url: '/orbcustomerdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'orbcustomerdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "OrbCustomerDetails",
				actionName:"ViewOrbCustomerDetails"
			},
		};

		const pmexpDashboardRequestCustomerOrbDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails.orbcustomerdetails',
			url: '/orbcustomerdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'orbcustomerdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "OrbCustomerDetails",
				actionName:"ViewOrbCustomerDetails"
			},
		};

		const pmexpMicrosoftOrbDetails: IStateConfig = {
			name: 'request.requestdetails.orbmicrosoftrdetails',
			url: '/orbmicrosoftrdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'orbmicrosoftrdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "OrbMicrosoftDetails",
				actionName:"ViewOrbMicrosoftDetails"
			},
		};

		const pmexpDashboardRequestMicrosoftOrbDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails.orbmicrosoftrdetails',
			url: '/orbmicrosoftrdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'orbmicrosoftrdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "OrbMicrosoftDetails",
				actionName:"ViewOrbMicrosoftDetails"
			},
		};

		const pmexpReviewandsubmit: IStateConfig = {
			name: 'request.requestdetails.reviewpursuitdetails',
			url: '/reviewpursuitdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'reviewpursuitdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "ReviewPursuitDetails",
				actionName:"ReviewPursuitRequestDetails"
			},
		};

		const pmexpDashboardRequestReviewandsubmit: IStateConfig = {
			name: 'pmexp.request.requestdetails.reviewpursuitdetails',
			url: '/reviewpursuitdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'reviewpursuitdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "ReviewPursuitDetails",
				actionName:"ReviewPursuitRequestDetails"
			},
		};

		const pmexpRequestApporvalDetails: IStateConfig = {
			name: 'request.requestdetails.approvaldetails',
			url: '/approvaldetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'approvaldetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "ApprovalDetails",
				actionName:"ViewApprovalDetails"
			},
		};

		const pmexpDashboardRequestApporvalDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails.approvaldetails',
			url: '/approvaldetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'approvaldetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "ApprovalDetails",
				actionName:"ViewApprovalDetails"
			},
		};

		const pmexpRequestSolutionDetails: IStateConfig = {
			name: 'request.requestdetails.solution',
			url: '/solution',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'solution',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "SolutionDetails",
				actionName:"ViewSolutionDetails"
			},
		};

		const pmexpDashboardRequestSolutionDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails.solution',
			url: '/solution',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'solution',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "SolutionDetails",
				actionName:"ViewSolutionDetails"
			},
		};

		const pmexpPresalesOpportunityPlan: IStateConfig = {
			name: 'presalesopportunityplan',
			url: '/presales/opportunity/:id/plan',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Presales Close Schedule',
				pageTitle: 'Presales Close Schedule',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
			},
		};

		/*csa routing */
		// const CSACommonLanding: IStateConfig = {
		// 	name: 'csxp',
		// 	url: '/csxp',
		// 	component: CsaCommonLandingComponent,
		// 	redirectTo: 'csxp.submitrequest',
		// 	// params: {msxid : ''},
		// 	data: {
		// 		headerName: 'CSA Management',
		// 		breadcrumbText: 'CSA Management',
		// 		pageTitle: 'CSA Management',
		// 		style: 'icon icon-people',
		// 		partnerTelemetryName: "CSXP",
		// 	},
		// };

		// const CSASubmitRequest: IStateConfig = {
		// 	name: 'csxp.submitrequest',
		// 	url: '/submitrequest/:msxid?',
		// 	component: CsaRequestFormComponent,
		// 	data: {
		// 		headerName: 'CSA Management',
		// 		breadcrumbText: 'CSA Management',
		// 		pageTitle: 'CSA Management',
		// 		style: 'icon icon-people',
		// 		partnerTelemetryName: "CSXP",
		// 		appNameForTelemetry:'PmexApp',
		// 		pageNameForTelemetry:'CCERequestForm',
		// 		actionName:'SubmitRequest',
		// 		ocvAreaName: 'CCESubmitRequest'
		// 	},
		// };

		// const CSASearch: IStateConfig = {
		// 	name: 'csxp.search',
		// 	url: '/search',
		// 	component: CsaSearchNavigationComponent,
		// 	data: {
		// 		headerName: 'CSA Search',
		// 		breadcrumbText: 'CSA Search',
		// 		pageTitle: 'CSA Search',
		// 		style: 'icon icon-people',
		// 		partnerTelemetryName: "CSAExp",
		// 	},
		// };
		// const csaRequestDetails: IStateConfig = {
		// 	name: 'csxp.requestdetails',
		// 	url: '/requestdetails/:id',
		// 	component: CsaRequestDetailsComponent,
		// 	data: {
		// 		headerName: 'CSA Request Details',
		// 		breadcrumbText: 'CSA Request Details',
		// 		pageTitle: 'CSA Request Details',
		// 		style: 'icon icon-people',
		// 		partnerTelemetryName: "CSXP",
		// 		appNameForTelemetry:'PmexApp',
		// 		pageNameForTelemetry:'CCERequestForm',
		// 		actionName:'SubmitRequest',
		// 		ocvAreaName: 'CCERequestDetails'
		// 	},
		// };

		const pmexpDaRequestdetails: IStateConfig = {
			name: 'pmexp.request.darequestdetails',
			url: '/darequestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'darequestdetails',				
				featureName:"EightHrDARequestDetails",				
				actionName:"ViewDARequestDetails"
			},
		};

		const daRequestdetails: IStateConfig = {
			name: 'request.darequestdetails',
			url: '/darequestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				partnerTelemetryName: "PursuitExp",
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'darequestdetails',				
				featureName:"EightHrDARequestDetails",				
				actionName:"ViewDARequestDetails"
			},
		};

		const pmexpQualificationbDetails: IStateConfig = {
			name: 'request.requestdetails.qualificationdetails',
			url: '/qualificationdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'qualificationdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "QualificationDetails",
				actionName:"ViewQualificationDetails"
			},
		};

		const pmexpDashboardRequestQualificationOrbDetails: IStateConfig = {
			name: 'pmexp.request.requestdetails.qualificationdetails',
			url: '/qualificationdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'qualificationdetails',
				partnerTelemetryName: "PursuitExp",
				featureName:"PresalesRequestDetails",
				subFeatureName: "QualificationDetails",
				actionName:"ViewQualificationDetails"
			},
		};

		const pmexpAmendmentRequestdetails: IStateConfig = {
			name: 'request.amendmentrequestdetails',
			url: '/amendmentrequestdetails',
			data: {
				headerName: 'Pursuit Management',
				breadcrumbText: 'Request details',
				pageTitle: 'Request details',
				style: 'icon icon-people',
				appNameForTelemetry: 'PursuitExp',
				pageNameForTelemetry: 'amendmentrequestdetails',
				partnerTelemetryName: "PursuitExp",
			},
		};

		const routeInfo: IRouteInfo = {
			applicationName: 'PmexApp',
			sharedBundles: ['Pursuitexp-Bundle'],
			routes: [pmExpDashboard,
				pmExpDashboardTab,
				pmExpDashboardTabRequestId,
				pmExpDashboardTabRequestTabId,
				pmExpDashboardTabRequest,
				pmExpDashboardTabRequestIdRequest,
				pmExpDashboardTabRequestIdPursuitPlan,
				pmExpDashboardTabRequestIdStaffing,
				pmexpdetails,
				pmexppursuitplan,
				pmexppursuitstatus,
				pmexpPursuitStatus,
				pmExpPresalesHours,
				pmExpRequestHistory,
				//pmExpRequestSucessEngagements,
				pmexpApproverManagement,
				pmexpOfferingManagement,
				pmexpRoleManagement,
				pmexpRequestdetails,
				pmexpPrequalRequestdetails,
				pmexpRequestDashboard,
				pmexpRequestHistory,
				//pmexpRequestSucessEngagements,
				pmexpPresalesHours,
				pmexpRequestIdStaffing,
				pmexpRequestPursuitPlan,
				pmExpAdvanceSearch,
				pmexpUnauthorized,
				pmexpPursuitPlanManagement,
				pmExpPreQualDashboardTabRequestIdRequest,
				pmExpprequalDashboardTabRequest,
				pmexpPursuitTeam,
				pmexpPursuitTeamAdv,
				pmexpPursuitComments,
				pmexpPursuitCommentsAdv,
				pmexpPursuitSolutioning,
				pmexpDashboard,
				pmexpDashboardSearch,
				pmexpLanding,
				pmexpDashboardRequestDetails,
				pmexpDashboardPrequalRequestDetails,
				pmexpDashboardSearchWithParam,
				pmexpAnnouncementComponent,
				pmexpInitiativeManagement,
				pmexpCustomerDetails,
				pmexpCustomerOrbDetails,
				pmexpMicrosoftOrbDetails,
				pmexpReviewandsubmit,
				pmexpOpportunityDetails,
				pmexpDashboardRequest,
				pmexpDashboardRequestCustomerDetails,
				pmexpDashboardRequestOpportunityDetails,
				pmexpDashboardRequestCustomerOrbDetails,
				pmexpDashboardRequestMicrosoftOrbDetails,
				pmexpDashboardRequestReviewandsubmit,
				pmexpDashboardRequestSolutionDetails,
				pmexpRequestApporvalDetails,
				pmexpRequestSolutionDetails,
				pmexpDashboardRequestApporvalDetails,
				pmexpDashboardPursuitCommentsAdv,
				pmexpDashboardPursuitOrbReviewAdv,
				pmexpDashboardPursuitStatusAdv,
				pmexpDashboardPursuitTeamAdv,
				pmexpRequestPursuitPlanSaved,
				// CSACommonLanding,
				// CSASubmitRequest,
				// // CSASearch,
				// csaRequestDetails,
				//pmexpRequestSucessEnaggaementSaved,
				pmexpDaRequestdetails,
				daRequestdetails,
				pmexpPresalesOpportunityPlan,
				pmExpOrbReview,
				pmexpOrbReview,
				pmexpQualificationbDetails,
				pmexpDashboardRequestQualificationOrbDetails,
				pmexpAmendmentRequestdetails
			],
		};

		return routeInfo;
	}

	getBundles(): IPartnerBundle[] {
		const baseUrl = appSettings().cdnBaseUrl;
		const bundle: IPartnerBundle = {
			name: 'Pursuitexp-Bundle',
			files: [
				`${baseUrl}/vendor.bundle.js`,
				`${baseUrl}/styles.bundle.js`,
				`${baseUrl}/main.bundle.js`,
			],
			sequentialLoading: true,
		};
		return [bundle];
	}

	// getSharedComponentsInfo(): ISharedComponents {
	// 	const csaSearchNavigationComponent: ISharedComponentConfig = {
	// 		component: CsaSearchNavigationComponent,
	// 		ngModule: CSAModule,
	// 		componentDescription: 'CCE Component that displays My Requests',
	// 		componentInputs: [],
	// 		componentOutputs: [],
	// 		componentName: 'app-csa-search-navigation'
	// 	}

	// 	return {
	// 		appName: 'PmexApp',
	// 		components: [csaSearchNavigationComponent],
	// 		disableSharing: false,
	// 		sharedBundles: ['Pursuitexp-Bundle']
	// 	}
	// }
}

PartnerAppRegistrationService.registerLazyLoadedApp(PmexApp_routes);
